<template>
    <RoutesModal
        v-model:routesVisible="routesVisible"
        routeType="INTEGRATED"
        @select="onSelectedRoute"
    />
    <CheckPlan v-model:planVisible="planVisible" type="PRODUCT_CHECK" @onSelect="onChangePlan" />
    <a-modal
        v-model:visible="visible"
        :title="`${upDateId ? '编辑' : '新增'}标准产品方案`"
        @cancel="handleCancel"
        :footer="null"
        :destroyOnClose="true"
        width="700px"
    >
        <a-form
            :model="formData"
            ref="formRef"
            name="basic"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 18 }"
            autocomplete="off"
            @finish="onFinish"
        >
            <a-row :span="24" class="margin-bottom">
                <a-col :span="24">
                    <a-form-item
                        label="标准产品方案编码"
                        name="code"
                        :rules="[
                            { required: true, message: '请输入标准产品方案编码' },
                            { validator: checkAllSpace },
                        ]"
                        style="margin-bottom: 20px"
                    >
                        <a-form-item>
                            <div class="contant-item">
                                <a-input
                                    style="width: 73%; margin-right: 20px"
                                    :disabled="sysCreate || Boolean(upDateId)"
                                    v-model:value="formData.code"
                                    :maxlength="100"
                                    showCount
                                ></a-input>
                                <a-checkbox
                                    :disabled="Boolean(upDateId)"
                                    v-model:checked="sysCreate"
                                    v-if="!upDateId"
                                >
                                    系统生成
                                </a-checkbox>
                            </div>
                        </a-form-item>
                    </a-form-item>
                </a-col>
            </a-row>

            <a-form-item
                label="标准产品方案名称"
                name="name"
                :rules="[
                    { validator: checkAllSpace },
                    { required: true, message: '请输入标准产品方案名称' },
                ]"
            >
                <a-input v-model:value="formData.name" :maxlength="100" showCount />
            </a-form-item>

            <a-form-item
                label="工艺路线"
                name="processId"
                :rules="[{ required: true, message: '请选择工艺路线' }]"
            >
                <SelectInput
                    :title="formData.processName"
                    :onClick="() => (routesVisible = true)"
                    :showDeleteIcon="false"
                ></SelectInput>
            </a-form-item>

            <a-form-item
                label="质检方案"
                name="qcPlanCode"
                :rules="[{ required: true, message: '请选择质检方案' }]"
            >
                <a-form-item>
                    <SelectInput
                        :title="formData.qcPlanName"
                        :showDeleteIcon="false"
                        :onClick="() => (planVisible = true)"
                    ></SelectInput>
                </a-form-item>
            </a-form-item>

            <a-form-item :wrapper-col="{ offset: 19, span: 7 }">
                <a-button style="margin-right: 8px" @click="handleCancel">取消</a-button>
                <a-button type="primary" html-type="submit">提交</a-button>
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs } from "vue";
import { apiStandardProductPlanUpdate, apiStandardProductPlanIndexData } from "@/api/baseInfo";
import Select from "@/components/Select";
import DictionaryCategory from "@/components/CategoryModal";
import { showMessage, checkValueIsAvailable, getAutoCreateCode } from "@/utils/common";
import SelectInput from "@/components/SelectInput";
import RoutesModal from "@/components/RoutesModal";
import CheckPlan from "@/components/CheckPlan";
export default defineComponent({
    props: ["upDateId"],
    emits: ["eventUpdateOk"],
    components: {
        Select,
        DictionaryCategory,
        SelectInput,
        RoutesModal,
        CheckPlan,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            routesVisible: false,
            sysCreate: false,
            planVisible: false,
            formRef: null,
            formData: {
                id: "",
                name: "",
                code: "",
                processId: "",
                processName: "",
                qcPlanCode: "",
                qcPlanName: "",
            },
        });

        const handleCancel = () => {
            state.formRef.resetFields();
            state.formData.id = "";
            state.formData.processId = "";
            state.formData.processName = "";
            state.formData.qcPlanCode = "";
            state.formData.qcPlanName = "";
            state.visible = false;
            state.sysCreate = false;
            state.autoCreateCode = "";
            emit("update:upDateId", null);
        };

        const onFinish = async () => {
            let res = await apiStandardProductPlanUpdate(state.formData);
            if (res.status === "SUCCESS") {
                showMessage("success", res.msg);
                handleCancel();
                emit("eventUpdateOk");
            }
        };

        const onChangePlan = arr => {
            const [value] = arr || [];
            state.formData.qcPlanName = value ? value?.name : "";
            state.formData.qcPlanCode = value ? value?.code : "";
        };

        const checkAllSpace = (rule, value) => {
            if (value && checkValueIsAvailable(value, "spaceAll")) {
                return Promise.reject("不能全是空格");
            } else {
                return Promise.resolve();
            }
        };

        const onSelectedRoute = route => {
            state.formData.processId = route?.id;
            state.formData.processName = route?.name;
        };

        const getIndexData = async id => {
            let res = await apiStandardProductPlanIndexData(id);
            if (res.status === "SUCCESS") {
                const { basStandardProductPlanInfo } = res?.data;
                Object.keys(state.formData).forEach(key => {
                    state.formData[key] = basStandardProductPlanInfo
                        ? basStandardProductPlanInfo?.[key]
                        : "";
                });
            }
        };

        const getOrder = async () => {
            let code = await getAutoCreateCode("BAS_STANDARD_PRODUCT_PLAN");
            state.autoCreateCode = code;
            state.formData.code = code;
        };

        watch(
            () => state.sysCreate,
            newVal => {
                if (newVal && !state.autoCreateCode) {
                    getOrder();
                } else {
                    state.formData.code = state.autoCreateCode;
                }
                if (!newVal) state.formData.code = "";
            }
        );

        watch(
            () => props.upDateId,
            newVal => {
                if (newVal !== null) {
                    state.visible = true;
                    newVal && getIndexData(newVal);
                } else {
                    state.visible = false;
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            onFinish,
            checkAllSpace,
            onSelectedRoute,
            onChangePlan,
        };
    },
});
</script>

<style lang="less" scoped>
.title {
    margin-bottom: 10px;
    font-weight: bold;
}
</style>
